import React from "react"
import styled from "styled-components"

const LineDiv = styled.div`
display: block;
width: 100%;
height: 1px;
background: black;
margin: 10px 0;
`

const HorizontalLine = () => <LineDiv></LineDiv>

export default HorizontalLine