import React from "react"

import DefaultLayout from "../layouts/default"
import TwoThirdsGrid from "../components/layout/TwoThirdsGrid"
import Text from "../components/text/Text"
import HeadlineWithSub from "../components/text/HeadlineWithSub"
import HorizontalLine from "../components/HorizontalLine"
import RoundedImage from "../components/RoundedImage"

const active = {
  title: "Portrait"
}

const Portrait = () =>
  <DefaultLayout image="/assets/images/IMG_9449.jpeg" active={active}>
    <TwoThirdsGrid textRight>
      <div>
        <HeadlineWithSub>
          <h1>Portrait</h1>
          <h4>
            Beobachterin, Pflanzensammlerin, Duftstrickerin, Raumschafferin
          </h4>
        </HeadlineWithSub>
        <div className="mobileDisplayNone"><RoundedImage image="/assets/images/portrait-new.jpg" contentImage /></div>
      </div>
      <Text>
        <p>
          <strong>
            Ich bin an der Aare aufgewachsen und habe bereits als Kind die Natur am Wasser beobachtet. Der Lebensfluss
            hat mich an verschiedene Ufer getragen. Manchmal bin ich länger verweilt, manchmal habe ich kürzere Zeit
            innegehalten. Ich fühle mich der Fluidität, dem Wandelbaren verbunden. Der Prozess, die Entwicklung – beide
            bezeichnen eine Bewegung und nicht einen Zustand – führen immer weiter. Das Leben findet eingewoben in den
            Jahreszeiten und in der Landschaft statt, aber die Fäden reichen weit hinein in die Beziehung von allem
            Leben, das von den Dimensionen Zeit und Raum enthoben ist. Mit den Jahren habe ich meine Wahrnehmung
            verfeinert und eine tiefe Beziehung zur Natur aufgebaut. Das schafft Vertrauen für den Alltag, in
            herausfordernden Situationen gestärkt zu sein. Die Natur zeigt viele Themen, mit denen wir uns in unserem
            Leben auseinandersetzen wie Licht und Schatten, Wachstum und Tod, Halten und Loslassen.
          </strong>
        </p>
        <div className="tdDisplayNone"><RoundedImage image="/assets/images/UNADJUSTEDNONRAW_thumb_5d4d.jpg"
                                                     contentImage /></div>
        <p>
          Ich gehe davon aus, dass Leben auf ganz unterschiedliche Art gelebt werden kann und begleite andere Menschen
          staunend und interessiert, in welchen Lebenswelten sie sich bewegen und welche Lebensentwürfe sie für sich
          skizziert haben.
        </p>

        <h3>Aus- und Weiterbildung</h3>
        <HorizontalLine />
        <p>
          <strong>2024</strong><br />
          Feldbotanikzertifikat Iris 400
        </p>
        <p>
          <strong>2023</strong><br />
          Phytotherapie-Lehrgang bei Martin Koradi, Winterthur (bis 2025) <br/>
          Ökologie & Biographie - zum Einfluss ökologischer Kontexte auf die Persönlichkeitsentwicklung <br /> Schloss
          Hofen, Lochau
        </p>
        <p>
          <strong>2020 - 2022</strong><br />
          Kunsttherapeutin iac Fachrichtung Mal- & Gestaltungstherapie
        </p>
        <p>
          <strong>2015 – 2018</strong><br />
          Psychosoziale Beratung Master of Science MSc<br />
          CAS Beratungstraining FH St. Gallen<br />
          CAS Krisenintervention FH Vorarlberg in Kooperation mit der FH St. Gallen<br />
          CAS Mediation FH Vorarlberg in Kooperation mit der FH St. Gallen plus Weiterbildung zur Erlangung des
          Abschlusses Mediator*in
        </p>
        <p>
          <strong>2006</strong><br />
          Hochschule für Heilpädagogik HfH Zürich<br />
          Schulische Heilpädagogik Master of Arts MA<br />
          Schwerpunkt Special Needs Education
        </p>
        <p>
          <strong>2003</strong><br />
          Lehrgang Kunstagogik, Agogis Zürich
        </p>
        <p>
          <strong>1996</strong><br />
          Lehrerin Textiles Werken, Kantonales Seminar Brugg
        </p>

        <h3>Berufspraxis</h3>
        <HorizontalLine />
        <p>
          <strong>aktuell</strong><br />
          Coaching in psychosozialen und insbesondere stress- und belastungsbedingten Themen und Kunsttherapie in
          eigener Praxis <br />
          Unterrichtstätigkeit Fachschule für Figurenspieltherapie Olten<br />
          Heilpädagogin an der Volksschule in einem Time-in-Projekt
        </p>
        <p>
          <strong>Langjährige und/oder vergangene Tätigkeit</strong><br />
          Beratung und Unterstützung von Kindern und Jugendlichen mit speziellen Bedürfnissen<br />
          Beratung und Unterstützung von Kindern und Jugendlichen mit Hörbeeinträchtigungen<br />
          Elternberatung <br />
          Beratung des pädagogischen und therapeutischen Umfeldes <br />
          Unterrichtstätigkeit als Heilpädagogin in Sonderschulen und der Volksschule <br />
          Schulleitung Sonderschulinternat <br />
          Schulleitung kunsttherapeutisches Ausbildungsinstitut
        </p>
        <p>
          <strong>Praktika </strong><br />
          Psychiatrie St. Gallen <br />
          Ekkharthof, Lengwil
        </p>
      </Text>
    </TwoThirdsGrid>
  </DefaultLayout>

export default Portrait